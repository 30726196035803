<template>
  <div>
    <div class="loading" delay-hide="50000"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    checkUserLogin() {
      localStorage.setItem('username', this.$route.query.username)
      localStorage.setItem('user_id', this.$route.query.user_id)
      localStorage.setItem('mini_course_id', this.$route.query.minicourse)
      const username = localStorage.getItem('username')
      const userId = localStorage.getItem('user_id')
      const mini_course = localStorage.getItem('mini_course_id')
      console.log(this.$route.query)
      const params = {
        username: username,
      }
      if (this.$route.query?.admin_id) {
        params.admin_id = this.$route.query.admin_id
      }
      this.$store.dispatch('checkUserLogin', params).then(res => {
        console.log('checkUserLogin', res)
        if (res.token != '') {
          console.log(userId, 'userId')
          const action = this.$route.query.action
          switch (action) {
            case 'orders':
              console.log('orders ==>')
              // this.$store.dispatch('getDataMember', userId).then(user => {
              //   console.log(user)

              //   if (user == 'Unauthorized') {
              //     location.reload()
              //   }
              this.$router.push('/openbill')
              // })
              break
            case 'pay':
              this.$store.dispatch('getProviderSalesByOrder', { id: this.$route.query.order_id, type: 3 }).then(res => {
                let payload = { id: this.$route.query.order_id, providerSales: res.userCourse }
                console.log(res, 'res-getProviderSalesByOrder')
                this.$store.dispatch('getOrderAgain', payload).then(res => {
                  const user_id = this.$route.query.user_id
                  console.log(user_id)
                  localStorage.setItem('user_id', user_id)
                  this.$router.push('/openbill/detail/' + this.$route.query.order_id)
                })
              })
              break
            case 'pay_usercourse':
              let payload_before = { id: this.$route.query.order_id, providerSales: {} || {} }
              this.$store.dispatch('getUserCourse', payload_before).then(resCourse => {
                let order_id = resCourse.order_id
                if (order_id == 0) {
                  this.$store
                    .dispatch('getProviderSales', { data: [this.$route.query.order_id], type: 3 })
                    .then(res => {
                      let payload = { id: this.$route.query.order_id, providerSales: res.userCourse[0] || {} }
                      this.$store.dispatch('getUserCourse', payload).then(resCourse => {
                        const user_id = this.$route.query.user_id
                        localStorage.setItem('user_id', user_id)
                        this.$router.push('/openbill/customcourse')
                      })
                    })
                } else {
                  this.$store.dispatch('getProviderSalesByOrder', { id: order_id, type: 3 }).then(res => {
                    let payload = { id: order_id, providerSales: res.userCourse }
                    this.$store.dispatch('getOrderAgain', payload).then(res => {
                      const user_id = this.$route.query.user_id
                      localStorage.setItem('user_id', user_id)
                      this.$router.push('/openbill/detail/' + order_id)
                    })
                  })
                }
              })

              break
            case 'minicourse':
              this.$store.dispatch('getProviderSales', { data: [mini_course], type: 4 }).then(res => {
                let payload = { id: mini_course, providerSales: res.userCourseMini[0] || {} }
                this.$store.dispatch('getDataMiniCourse', payload).then(res => {
                  console.log(res)
                  const id_user = localStorage.getItem('user_id')
                  this.$store.dispatch('getDataMember', id_user).then(res => {
                    console.log(res)
                    this.$router.push('/openbill/minicourse')
                  })
                })
              })
              break
            case 'select-courses':
              console.log(this.$route.query)
              var array_id = this.$route.query.idCourse
              if (typeof array_id == 'string') {
                array_id = [array_id]
              }
              let params_data = '?'
              if (Array.isArray(array_id)) {
                for (let i = 0; i < array_id.length; i++) {
                  params_data += i == 0 ? `id=${array_id[i]}` : `&id=${array_id[i]}`
                }
              } else {
                params_data += `id=${array_id}`
              }
              console.log(params_data)
              const user_id = this.$route.query.user_id
              localStorage.setItem('user_id', user_id)
              this.$store.dispatch('getProviderSales', { data: array_id, type: 3 }).then(res => {
                let payload = { id: params_data, providerSales: res.userCourse }
                this.$store.dispatch('getListCourseCrm', payload).then(res => {
                  console.log(res)
                  this.$router.push('/openbill/customcourse')
                })
              })
              break
            case 'select-minicourses':
              console.log(this.$route.query)
              var array_id = this.$route.query.idCourse
              if (typeof array_id == 'string') {
                array_id = [array_id]
              }
              let params_dataMini = '?'
              if (Array.isArray(array_id)) {
                for (let i = 0; i < array_id.length; i++) {
                  params_dataMini += i == 0 ? `id=${array_id[i]}` : `&id=${array_id[i]}`
                }
              } else {
                params_dataMini += `id=${array_id}`
              }
              console.log(params_dataMini)
              const user_id_mini = this.$route.query.user_id
              localStorage.setItem('user_id', user_id_mini)
              this.$store.dispatch('getProviderSales', { data: array_id, type: 4 }).then(res => {
                let payload = { id: params_dataMini, providerSales: res.userCourseMini }
                this.$store.dispatch('getListMiniCourseCrm', payload).then(res => {
                  console.log(res)
                  this.$router.push('/openbill/minicourse')
                })
              })
              break
            default:
              // this.$store.dispatch('getDataMember', userId).then(user => {
              //   console.log(user)

              //   if (user == 'Unauthorized') {
              //     location.reload()
              //   }
              this.$router.push('/openbill')
              // })
              break
          }
        }
        // if (this.$route.query.user_id) {
        //   this.$store.dispatch('getDataMember', userId).then(user => {
        //     console.log(user)

        //     if (user == 'Unauthorized') {
        //       location.reload()
        //     }
        //     this.$router.push('/openbill')
        //   })
        // }
        //  else if (this.$route.query.minicourse) {
        //     this.$store.dispatch('getDataMiniCourse', mini_course).then(res => {
        //       console.log(res)
        //       const id_user = res.user_id
        //       localStorage.setItem('user_id', id_user)
        //       this.$store.dispatch('getDataMember', id_user).then(res => {
        //         console.log(res)
        //         this.$router.push('/openbill/minicourse')
        //       })
        //     })
        //   } else if (this.$route.query.course_id) {
        //     console.log('aaaaaaaa')
        //     this.$store.dispatch('getCoursePayAgain', this.$route.query.course_id).then(res => {
        //       console.log(res)
        //       const user_id = this.$route.query.user_id
        //       console.log(user_id)
        //       localStorage.setItem('user_id', user_id)
        //       this.$store.dispatch('getDataMember', user_id).then(res => {
        //         console.log(res)
        //       })
        //     })
        //   }
        // }
      })
    },
    loginPos() {
      localStorage.setItem('username', this.$route.query.username)
      localStorage.setItem('user_id', this.$route.query.user_id)
      localStorage.setItem('mini_course_id', this.$route.query.minicourse)
      const username = localStorage.getItem('username')
      const params = {
        username: username,
      }
      if (this.$route.query?.admin_id) {
        params.admin_id = this.$route.query.admin_id
      }
      this.$store.dispatch('checkUserLogin', params).then(res => {
        if (res.token != '') {
          this.getClinicAddress()
        }
      })
    },
    getClinicAddress() {
      this.$store.dispatch('getClinicAddress').then(res => {
        if (res.length == 0) {
          if (this.$route.name != 'billing-form') {
            location.replace('/billing-form')
          }
        } else {
          this.handleCase()
        }
      })
    },
    handleCase() {
      const mini_course = localStorage.getItem('mini_course_id')
      const action = this.$route.query.action
      switch (action) {
        case 'orders':
          console.log('orders ==>')
          this.$router.push('/openbill')
          break
        case 'pay':
          this.$store.dispatch('getProviderSalesByOrder', { id: this.$route.query.order_id, type: 3 }).then(res => {
            let payload = { id: this.$route.query.order_id, providerSales: res.userCourse }
            console.log(res, 'res-getProviderSalesByOrder')
            this.$store.dispatch('getOrderAgain', payload).then(res => {
              const user_id = this.$route.query.user_id
              console.log(user_id)
              localStorage.setItem('user_id', user_id)
              this.$router.push('/openbill/detail/' + this.$route.query.order_id)
            })
          })
          break
        case 'pay_usercourse':
          let payload_before = { id: this.$route.query.order_id, providerSales: {} || {} }
          this.$store.dispatch('getUserCourse', payload_before).then(resCourse => {
            let order_id = resCourse.order_id
            if (order_id == 0) {
              this.$store.dispatch('getProviderSales', { data: [this.$route.query.order_id], type: 3 }).then(res => {
                let payload = { id: this.$route.query.order_id, providerSales: res.userCourse[0] || {} }
                this.$store.dispatch('getUserCourse', payload).then(resCourse => {
                  const user_id = this.$route.query.user_id
                  localStorage.setItem('user_id', user_id)
                  this.$router.push('/openbill/customcourse')
                })
              })
            } else {
              this.$store.dispatch('getProviderSalesByOrder', { id: order_id, type: 3 }).then(res => {
                let payload = { id: order_id, providerSales: res.userCourse }
                this.$store.dispatch('getOrderAgain', payload).then(res => {
                  const user_id = this.$route.query.user_id
                  localStorage.setItem('user_id', user_id)
                  this.$router.push('/openbill/detail/' + order_id)
                })
              })
            }
          })

          break
        case 'minicourse':
          this.$store.dispatch('getProviderSales', { data: [mini_course], type: 4 }).then(res => {
            let payload = { id: mini_course, providerSales: res.userCourseMini[0] || {} }
            this.$store.dispatch('getDataMiniCourse', payload).then(res => {
              console.log(res)
              const id_user = localStorage.getItem('user_id')
              this.$store.dispatch('getDataMember', id_user).then(res => {
                console.log(res)
                this.$router.push('/openbill/minicourse')
              })
            })
          })
          break
        case 'select-courses':
          console.log(this.$route.query)
          var array_id = this.$route.query.idCourse
          if (typeof array_id == 'string') {
            array_id = [array_id]
          }
          let params_data = '?'
          if (Array.isArray(array_id)) {
            for (let i = 0; i < array_id.length; i++) {
              params_data += i == 0 ? `id=${array_id[i]}` : `&id=${array_id[i]}`
            }
          } else {
            params_data += `id=${array_id}`
          }
          console.log(params_data)
          const user_id = this.$route.query.user_id
          localStorage.setItem('user_id', user_id)
          this.$store.dispatch('getProviderSales', { data: array_id, type: 3 }).then(res => {
            let payload = { id: params_data, providerSales: res.userCourse }
            this.$store.dispatch('getListCourseCrm', payload).then(res => {
              console.log(res)
              this.$router.push('/openbill/customcourse')
            })
          })
          break
        case 'select-minicourses':
          console.log(this.$route.query)
          var array_id = this.$route.query.idCourse
          if (typeof array_id == 'string') {
            array_id = [array_id]
          }
          let params_dataMini = '?'
          if (Array.isArray(array_id)) {
            for (let i = 0; i < array_id.length; i++) {
              params_dataMini += i == 0 ? `id=${array_id[i]}` : `&id=${array_id[i]}`
            }
          } else {
            params_dataMini += `id=${array_id}`
          }
          console.log(params_dataMini)
          const user_id_mini = this.$route.query.user_id
          localStorage.setItem('user_id', user_id_mini)
          this.$store.dispatch('getProviderSales', { data: array_id, type: 4 }).then(res => {
            let payload = { id: params_dataMini, providerSales: res.userCourseMini }
            this.$store.dispatch('getListMiniCourseCrm', payload).then(res => {
              console.log(res)
              this.$router.push('/openbill/minicourse')
            })
          })
          break
        default:
          this.$router.push('/openbill')
          break
      }
    },
  },
  mounted() {
    // this.checkUserLogin()
    this.loginPos()
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lobster');

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99;
}

.loading:after {
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: -30px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border: 6px solid #00205c;
  border-top: 6px dotted #00205c;
  border-bottom: 6px dotted #00205c;
  border-radius: 50%;
  animation: loading 2s infinite;
}

.loading:before {
  font-family: 'Lobster', cursive;
  font-size: 20px;
  letter-spacing: 1px;
  color: #00205c;
  content: 'Loading...';
  position: absolute;
  top: 57%;
  text-align: center;
  right: 0;
  left: 0;
  margin: auto;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(360deg);
  }
}

#AliRanjbar {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 13px;
  color: #444;
  font-family: 'Acme', sans-serif;
  z-index: 99;
}
</style>
